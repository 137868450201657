import { Navbar } from "flowbite-react";
import { Link } from 'react-router-dom';
import logo from '../img/logo.png';
import '../index.css';
import '../components/Header.css';

export default function Header({ currentPage }) {
  return (
    <div className={`header-outer-container`} >
        <Navbar className={`custom-navbar h-fixed fixed w-full z-50`}>
          <Navbar.Brand>
            <img
              src={logo}
              alt="Logo 2"
              className="mr-2  object-contain"
              style={{ width: "120px", height: "60px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle id="custom-navbar-toggler" />
          <Navbar.Collapse >
              {currentPage !== 'home' && (
                <Link to="/">
                  <li className="nav-buttons">
                  <button>Home</button>
                  </li>
                </Link>
              )}
              {currentPage !== 'engineering' && (
                <Link to="/Engineering">
                  <li className="nav-buttons">
                  <button>Engineering & Permitting</button>
                  </li>
                </Link>
              )}
              {currentPage !== 'invest' && (
                <Link to="/invest-with-us">
                  <li className="nav-buttons">
                  <button>Invest with Us</button>
                  </li>
                </Link>
              )}
              {currentPage !== 'sport' && (
                <Link to="/sport-courts">
                  <li className="nav-buttons">
                  <button>Sport Courts</button>
                  </li>
                </Link>
              )}
              {currentPage !== 'affordable' && (
                <li className="nav-buttons">
                <button>Affordable Houses</button>
                </li>
              )}
          
          </Navbar.Collapse>
        </Navbar>
        </div>
  );
}
