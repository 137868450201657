import React from 'react';

const ContactoModal = ({ selectedProduct, closeContactPanel, message }) => {
  const defaultMessage = `Hello, I am interested in ${selectedProduct}.`;
  const finalMessage = message || defaultMessage;
  const encodedMessage = encodeURIComponent(finalMessage);


  return (
    <div className="contact-panel-overlay">
      <div className="contact-panel">
        <h3>Contact Us about {selectedProduct}</h3>
        <p>You can reach us via the following channels:</p>
        <div className="footer-icons">
          <a href={`https://wa.me/your-phone-number?text=${encodedMessage}`} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i> {/* WhatsApp Icon */}
          </a>
          <a href="https://www.instagram.com/your-instagram" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i> {/* Instagram Icon */}
          </a>
          <a href="https://www.linkedin.com/in/your-linkedin" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i> {/* LinkedIn Icon */}
          </a>
          <a href={`https://m.me/your-facebook-username?ref=${encodedMessage}`} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i> {/* Facebook Icon */}
          </a>
        </div>
        <button onClick={closeContactPanel} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default ContactoModal;