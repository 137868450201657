import hero from '../img/Engeneering.png';
import solutions from '../img/thisisengineering-BxZSdcdG3Zc-unsplash.jpg'
import '../pages/Engineering.css';
import icon from '../img/PARA PAGINA/INGE.png';
import contactImage from '../img/contact-image.jpg'
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Socials from '../components/Socials';
import Areas from '../components/Areas';

import Construction from '../components/new force construction';
import ContactForm from '../components/ContactForm';
import '@fortawesome/fontawesome-free/css/all.min.css';

import engineerImage from '../img/Hero1.png';
import renderImage from '../img/3D_Render.png';
import housesImage from '../img/hero-invest.png';
import Header from '../components/Header';

const heroCards = [
    {
        title: '3D Render',
        img: renderImage,
        buttonText: 'Use'
    },
    {
        title: 'Available Houses',
        img: housesImage,
        buttonText: 'See More'
    }
];

function Engineering(){

    return(
        <div>
            {/* Comentario de Prueba */}
            {/*Cabecera*/}
            <Header  currentPage="engineering"/>

            <Hero
                  heroTitle="New Force Construction"
                  heroSubtitle="We handle everything from concept to completion."
                  heroImg={engineerImage}
                  heroCards={heroCards}
                 />
            <Areas/>
            <Construction/>

                <section>
                    <div className="solutions">
                        <div className="image-section">
                            <img id='faded' src={solutions} alt="Engineering Project" className="project-image"></img>
                        </div>
                        <div className="text-section">
                            <h2>Engineering Solutions geared for your needs.</h2>

                            <ul className={'text-black'}>

                                <li><img id="icon" src={icon} alt="Project Management"/> One-on-one / Engineering Consultations</li>
                                <li><img id="icon" src={icon} alt="Project Management"/> Project Management</li>
                                <li><img id="icon" src={icon} alt="Project Management"/>Engineering Professional Services</li>
                            </ul>
                        </div>
                    </div>
                </section>

                 <section  id="contact" className="contact-section">
                  <ContactForm />
                  <img src={contactImage} alt="Contact" />
                </section>
            <Socials/>

            <ContactForm/>
            <Footer/>

                
        </div>

        

    )
}

export default Engineering;
