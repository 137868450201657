// AreasOfExpertise.jsx
import React from 'react';
import './Areas.css'; // Import your CSS styles

import Icono from './Icono_titulo'


import Inge from '../img/PARA PAGINA/INGE.png'
import Ingenieros from '../img/PARA PAGINA/pngwing.com (41).png'

const AreasOfExpertise = () => {
  return (
    <div className="expertise-container">

      <div className="expertise-text text-black">

        <h2>Areas of Expertise</h2>

        <div className="expertise-item">
            <Icono icon={Inge} titulo="Professional Engineering Services" />
            <p>Engineering consultation, engineer letters, permitting, planning, feasibility studies, PD&E Studies, QAQC, green building, Public Art Monuments, and more.</p>
        </div>


        <div className="expertise-item">
            <Icono icon={Inge} titulo="Project Management" />
            <p>Schedule optimization, Shop Drawing Reviews, RFI/Submittal Management, Review Pay Requests, Subcontractor management, and coordination.</p>
        </div>
        
        <div className="expertise-item">
            <Icono icon={Inge} titulo="Develoment Studies" />
            <p>Maximize your resources and the potential opportunities to start your projects. Lot fit analysis, site development, and more.</p>
        </div>

        
        <div className="expertise-item">
            <Icono icon={Inge} titulo="Engineering Design" />
            <p>Residential New Construction and Remodeling, Shipping Container Homes Design and Plans, Bridge Design, Miscellaneous structures, Retaining Walls, Noise Walls, Bridge Repairs, and Rehabilitation.</p>
        </div>
      </div>
      <div className="expertise-image">
        <img src={Ingenieros} alt="Engineers looking at plans" />
      </div>
    </div>
  );
};

export default AreasOfExpertise;
