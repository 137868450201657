// src/pages/InvestWithUs.js
import React from 'react';
import './InvestWithUs.css';

import Header from '../components/Header';
import Hero from '../components/Hero';
import IconoTitulo from '../components/Icono_titulo';
import icon from '../img/PARA PAGINA/CASA.png'
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

import engineerImage from '../img/Hero1.png';
import renderImage from '../img/3D_Render.png';
import housesImage from '../img/hero-invest.png';

import contactImage from '../img/contact-image.jpg'
import Socials from '../components/Socials';

const heroCards = [
    {
        title: '3D Render',
        img: renderImage,
        buttonText: 'Use'
    },
    {
        title: 'Available Houses',
        img: housesImage,
        buttonText: 'See More'
    }
];

function InvestWithUs() {
  return (
    <div className="invest-with-us-page">
      {/* Header */}
          <Header currentPage="invest"/>
      <Hero
                  heroTitle="New Force Construction"
                  heroSubtitle="We handle everything from concept to completion."
                  heroImg={engineerImage}
                  heroCards={heroCards}
                 />
      {/* Hero Section */}
      
      {/* Services Section */}

      <section className="invest-opportunities">
        <h2>Our Investment Opportunities</h2>
        <div className="invest-cards">
          <div className="invest-card">
            <IconoTitulo icon={icon} titulo="Residential Real Estate" ancho={"100%  "}/>
            <p>Invest in residential properties with strong market growth potential.</p>
          </div>
          <div className="invest-card">
            <IconoTitulo icon={icon} titulo="Commercial Projects" ancho={"100%"}/>
            <p>Opportunities in commercial spaces for sustainable businesses.</p>
          </div>
          <div className="invest-card">
            <IconoTitulo icon={icon} titulo="Green Energy" ancho={"100%"}/>
            <p>Invest in renewable energy projects to promote a greener future.</p>
          </div>
        </div>
      </section>

               <section  id="contact" className="contact-section">
                  <ContactForm />
                  <img src={contactImage} alt="Contact" />
                </section>
            <Socials/>
      <ContactForm />
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default InvestWithUs;
