import React from "react";

import IconoTitulo from "./Icono_titulo";
import paint from "../img/PARA PAGINA/PAINTING.png";
import "./Work.css";

const ExpertiseSection = () => {
  const services = [
    { icon: paint, title: "Painting", description: "Roofing services that include installation, repair, and maintenance for residential and commercial buildings, ensuring durability and safety." },
    { icon: paint, title: "Roofing", description: "Roofing services that include installation, repair, and maintenance for residential and commercial buildings, ensuring durability and safety." },
    { icon: paint, title: "Painting", description: "Roofing services that include installation, repair, and maintenance for residential and commercial buildings, ensuring durability and safety." },
    { icon: paint, title: "Roofing", description: "Roofing services that include installation, repair, and maintenance for residential and commercial buildings, ensuring durability and safety." },
  ];

  return (
    <section className="expertise-section">

      <div className="container-work">
        <p>New Force Construction</p>
        <h2>We work at everything you need to your house</h2>
        <div className="services">
          {services.map((service, index) => (
            <div key={index} className="service-card">

              <IconoTitulo icon={service.icon} titulo={service.title} ancho={"100%"} />
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExpertiseSection;
