import React from "react";

import Icono from './Icono_titulo'

import icon from '../img/PARA PAGINA/INGE.png'
import "./new force construction.css";




const ExpertiseGrid = () => {
  return (
    <div className="expertise_grid_container">
      <div className="expertise_grid">

        <Icono titulo="Engeneering Consulting" icon={icon} ancho={"25%"}/>
        <Icono titulo="Shipping Container Homres" icon={icon} ancho={"25%"}/>
        <Icono titulo="Engineering Letters" icon={icon} ancho={"25%"}/>
        <Icono titulo="Engineering Plans" icon={icon} ancho={"25%"}/>
        <Icono titulo="New Force Designs" icon={icon} ancho={"25%"}/>
        <Icono titulo="Public Art & Monument Engineering" icon={icon} ancho={"25%"}/>
        <Icono titulo="Interior Design" icon={icon} ancho={"25%"}/>
        <Icono titulo="Backyard Structures" icon={icon} ancho={"25%"}/>
        <Icono titulo="Plans & Details" icon={icon} ancho={"25%"}/>
      </div>
    </div>
  );
};

export default ExpertiseGrid;