import React from 'react';
import './Hero.css';
import './Catalog.css';

/*  Example of the heroCards prop
const heroCards = [
    {
        title: '3D Render',
        img: renderImage,
        buttonText: 'Use'
    },
    {
        title: 'Available Houses',
        img: housesImage,
        buttonText: 'See More'
    }
];
*/

const Hero = ({ heroTitle, heroSubtitle, heroImg, heroCards }) => {
    return (
        <div className="hero-container">
            <div className="background-overlay"></div>
            <div className="hero-main-container">
                <div className="hero-left">
                    <img src={heroImg} alt={heroTitle} className="hero-engineer-image" />
                    <div className="hero-left-content">
                        <h1 className="hero-title">{heroTitle}</h1>
                        <p className="hero-subtitle">{heroSubtitle}</p>
                        <div className="hero-buttons">
                            <button className="hero-button">Know Us</button>
                            <button className="hero-button">Contact Us</button>
                        </div>
                    </div>
                </div>
                <div className="hero-right">
                    {heroCards.map((card, index) => (
                        <div className="hero-card" key={index}>
                            <h2 className="card-title">{card.title}</h2>
                            <img src={card.img} alt={card.title} />
                            <button className="card-button">{card.buttonText}</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Hero;