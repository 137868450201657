import React, { useState } from 'react';
import './sport.css';
import walls from '../img/walls.jpg'; // Imagen para el hero
import '@fortawesome/fontawesome-free/css/all.min.css';

// Imágenes de las canchas
import pickleballImage from '../img/pickleballImage.jpg';
import tennisImage from '../img/tennisImage.jpg';
import bocceImage from '../img/bocceImage.jpg';
import padelImage from '../img/padelImage.jpg';
import volleyballImage from '../img/volleyballImage.jpg';
import soccerImage from '../img/soccerImage.jpg';
import Header from '../components/Header';
import Footer from '../components/Footer';
// Íconos en formato PNG
import futbolIcon from '../img/FUTBOL.png';
import tenisIcon from '../img/TENIS.png';
import padelIcon from '../img/PADEL.png';
import volleyballIcon from '../img/VOLLEYBALL.png';
import pickleballIcon from '../img/PICKLEBALL.png';
import bocceIcon from '../img/BOCCE.png';

import contactImage from '../img/contact-image.jpg'
import Socials from '../components/Socials';
import ContactForm from '../components/ContactForm';

const sports = [
  { name: "Pickleball", icon: pickleballIcon, images: [pickleballImage], description: "High-quality pickleball courts" },
  { name: "Tennis", icon: tenisIcon, images: [tennisImage], description: "Professional tennis courts with durable materials" },
  { name: "Bocce", icon: bocceIcon, images: [bocceImage], description: "Standard bocce courts for recreational and professional use" },
  { name: "Padel", icon: padelIcon, images: [padelImage], description: "Custom padel courts with the highest quality materials" },
  { name: "Volleyball", icon: volleyballIcon, images: [volleyballImage], description: "Indoor and outdoor volleyball courts" },
  { name: "Soccer", icon: futbolIcon, images: [soccerImage], description: "Soccer fields with premium turf" }
];

function SportCourts() {
  const [selectedSport, setSelectedSport] = useState(sports[0]); // Deporte predeterminado

  return (
    <div className="sport-courts">
      {/* Header */}
      <Header currentPage="sport"/>

      {/* Hero Section */}
      <section className="sport-hero" style={{ backgroundImage: `url(${walls})` }}>
        <div className="sport-hero-overlay">
          <h1>Sport Courts</h1>
        </div>
      </section>

      {/* Título sobre los botones */}
      <h2 className="section-title">Our available courts</h2>

      {/* Botones de Selección de Deportes */}
      <section className="sport-buttons">
        {sports.map((sport, index) => (
          <button
            key={index}
            onClick={() => setSelectedSport(sport)}
            className={`sport-button ${selectedSport.name === sport.name ? "active" : ""}`}
          >
            <img src={sport.icon} alt={`${sport.name} icon`} className="sport-icon" />
            <span>{sport.name}</span>
          </button>
        ))}
      </section>

      {/* Slideshow */}
      {selectedSport && (
        <section className="sport-slideshow">
          <div className="slideshow-image">
            <img src={selectedSport.images[0]} alt={`${selectedSport.name} court`} />
          </div>
          <div className="slideshow-info">
            <h2>{selectedSport.name}</h2>
            <p>{selectedSport.description}</p>
            <button className="contact-button">Know Us</button>
          </div>
        </section>
      )}

      <section  id="contact" className="contact-section">
                  <ContactForm />
                  <img src={contactImage} alt="Contact" />
                </section>
            <Socials/>
    </div>
  );
}

export default SportCourts;